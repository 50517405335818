import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import {
  SearchStudentsFilters,
  SearchStudentsStudent,
} from "@platform-app/app/core/api/models";
import { StudentService } from "@platform-app/app/core/api/services";
import { MyStudentsTabsCount } from "@platform-app/app/main/my-students/models";
import { finalize, of, Subject, takeUntil, tap } from "rxjs";

export interface MyStudentsStateModel {
  studentList: SearchStudentsStudent[] | null;
  totalCount: number;
  allCount: number;
  waitingToApplyCount: number;
  pendingCount: number;
  placedCount: number;
  archivedCount: number;
  currentPage: number;
  itemsPerPage: number;
  filters: SearchStudentsFilters;
  dataLoading: boolean;
  error: string | null;
}

export class LoadStudents {
  static readonly type = "[My Students] Load Students";
  constructor() {}
}

export class PageRangeChanged {
  static readonly type = "[My Students] Page Range Changed";
  constructor(public payload: { itemsPerPage: number }) {}
}

export class AddStudentDialogRequested {
  static readonly type = "[My Students] Add Student Dialog Requested";
}

export class PageNumberChanged {
  static readonly type = "[My Students] Page Number Changed";
  constructor(public payload: { pageNumber: number }) {}
}

export class UpdateMyStudentsFilters {
  static readonly type = "[My Students] Update Filters";
  constructor(public payload: { filters: SearchStudentsFilters }) {}
}

export class ResetStudentListPagination {
  static readonly type = "[My Students] Reset Student List Pagination";
}

@State<MyStudentsStateModel>({
  name: "MyStudents",
  defaults: {
    studentList: null,
    totalCount: 0,
    allCount: 0,
    waitingToApplyCount: 0,
    pendingCount: 0,
    placedCount: 0,
    archivedCount: 0,
    currentPage: 1,
    itemsPerPage: 10,
    filters: {
      tab: null,
    },
    dataLoading: false,
    error: null,
  },
})
@Injectable()
export class MyStudentsState {
  private cancelPrevious$ = new Subject<void>();

  constructor(private studentService: StudentService) {}

  @Selector()
  static dataLoading(state: MyStudentsStateModel) {
    return state.dataLoading;
  }

  @Selector()
  static studentList(state: MyStudentsStateModel) {
    return state.studentList;
  }

  @Selector()
  static count(state: MyStudentsStateModel) {
    return {
      total: state.totalCount,
      all: state.allCount,
      waitingToApply: state.waitingToApplyCount,
      pending: state.pendingCount,
      placed: state.placedCount,
      archived: state.archivedCount,
    } satisfies MyStudentsTabsCount;
  }

  @Selector()
  static itemsPerPage(state: MyStudentsStateModel) {
    return state.itemsPerPage;
  }

  @Selector()
  static currentPage(state: MyStudentsStateModel) {
    return state.currentPage;
  }

  @Action(LoadStudents)
  loadPrograms(ctx: StateContext<MyStudentsStateModel>) {
    return this.getStudents(ctx);
  }

  @Action(PageNumberChanged)
  pageNumberChanged(
    ctx: StateContext<MyStudentsStateModel>,
    action: PageNumberChanged,
  ) {
    ctx.patchState({
      currentPage: action.payload.pageNumber,
    });

    return this.getStudents(ctx);
  }

  @Action(PageRangeChanged)
  pageRangeChanged(
    ctx: StateContext<MyStudentsStateModel>,
    action: PageRangeChanged,
  ) {
    ctx.patchState({
      itemsPerPage: action.payload.itemsPerPage,
      currentPage: 1,
    });

    return this.getStudents(ctx);
  }

  @Action(UpdateMyStudentsFilters)
  updateFilters(
    ctx: StateContext<MyStudentsStateModel>,
    action: UpdateMyStudentsFilters,
  ) {
    const stateSnapshot = ctx.getState();
    const { tab = stateSnapshot.filters?.tab } = action.payload.filters;

    ctx.patchState({
      filters: {
        tab,
      },
      currentPage: 1,
    });

    return this.getStudents(ctx);
  }

  @Action(ResetStudentListPagination)
  resetStudentList(ctx: StateContext<MyStudentsStateModel>) {
    ctx.patchState({
      currentPage: 1,
      itemsPerPage: 10,
      studentList: null,
      totalCount: 0,
      filters: {
        tab: null,
      },
    });
  }

  private getStudents(ctx: StateContext<MyStudentsStateModel>) {
    const stateSnapshot = ctx.getState();
    this.cancelPrevious$.next();

    if (!stateSnapshot.studentList) {
      ctx.patchState({
        dataLoading: true,
      });
    }

    return this.studentService
      .studentPost({
        body: {
          pagination: {
            page: stateSnapshot.currentPage,
            itemsPerPage: stateSnapshot.itemsPerPage,
          },
          filters: stateSnapshot.filters,
        },
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            studentList: response.students,
            totalCount: response.totalCount,
            allCount: response.allCount,
            waitingToApplyCount: response.waitingToApplyCount,
            pendingCount: response.pendingCount,
            placedCount: response.placedCount,
            archivedCount: response.archivedCount,
          });
          return of(response);
        }),
        takeUntil(this.cancelPrevious$),
        finalize(() =>
          ctx.patchState({
            dataLoading: false,
          }),
        ),
      );
  }
}
